






















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vuelidate';

import FormField from '@/shared/resources/components/forms/FormField.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import FormFieldset from '@/shared/resources/components/forms/FormFieldset.vue';
import SelectField from '@/shared/resources/components/forms/fields/SelectField.vue';
import Divider from '@/shared/resources/components/Divider.vue';

import { AuthApplicationIndustry, AuthApplicationInterface } from '@/app/modules/auth/interfaces/AuthApplicationInterface';
import { SelectFieldOptionInterface } from '@/shared/lib/interfaces/SelectFieldOptionInterface';

import { applicationIndustryOptions } from '@/app/modules/auth/config/authConfig';

@Component({
  components: {
    SelectField,
    Divider,
    FormFieldset,
    InputField,
    FormField,
  },
})
export default class AuthApplicationFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private data!: AuthApplicationInterface;
  @Prop() private validation?: Validation;

  /**
   * Getters
   */
  private get industryOptions(): SelectFieldOptionInterface[] {
    return applicationIndustryOptions.map((industry: AuthApplicationIndustry) => {
      return {
        text: industry.name,
        value: industry,
      };
    });
  }

  /**
   * Display getters
   */
  private get displayIndustryCommentField(): boolean {
    return this.data.industry ? !!this.data.industry.commentRequired : false;
  }
}
