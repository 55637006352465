





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';

import AuthApplicationFieldset from '@/app/modules/auth/components/application/AuthApplicationFieldset.vue';

import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Alert from '@/shared/resources/components/Alert.vue';

import Api from '@/core/api/Api';
import { AuthApplicationInterface } from '@/app/modules/auth/interfaces/AuthApplicationInterface';
import { authApplicationValidation } from '@/app/modules/auth/config/authValidation';
import { ApiErrorResponseMessagesContract } from '@/core/api/errors/contracts/ApiErrorResponseContract';

@Component({
  components: {
    IconMessage,
    AuthApplicationFieldset,
    Alert,
    Button,
    Divider,
  },
})
export default class AuthLoginForm extends Vue {
  @Prop() private nip?: string;

  /**
   * Validations
   */
  @Validations()
  private get applicationRules() {
    return authApplicationValidation();
  }

  /**
   * Data
   */
  private data: AuthApplicationInterface = {
    nip: this.nip || '',

    name: '',
    address: '',
    zipCode: '',
    place: '',

    email: '',
    emailConfirm: '',
    phone: '',
    www: '',

    industry: null,
    industryComment: '',
  };
  private loading: boolean = false;
  private success: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displayForm(): boolean {
    return !this.success;
  }

  private get displaySuccess(): boolean {
    return this.success;
  }

  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Methods
   */
  private async sendApplication() {
    this.loading = true;
    this.errorMessage = null;

    try {
      await new Api()
        .data({
          ...this.getDataToRequest(),
          industry: this.data.industry!.name,
          industryComment: this.data.industry!.commentRequired ? this.data.industryComment : null,
        })
        .post('/application');

      this.success = true;
    } catch (ex) {
      if (ex.hasMessages()) {
        const errors = ex.getMessages().map((error: ApiErrorResponseMessagesContract) => {
          return this.$t('auth.application.errors.' + error.message);
        });

        this.errorMessage = errors.join('<br/>');
      } else if (ex.isUnprocessableEntity() || ex.isExpectationFailed()) {
        this.errorMessage = 'Sprawdź poprawność danych.';
      } else {
        this.errorMessage = 'Wystąpił nieoczekiwany błąd. Prosimy o&nbsp;kontakt.';
      }
    }

    this.loading = false;
  }

  private getDataToRequest(): any {
    return {
      nip: this.data.nip,

      name: this.data.name,
      address: this.data.address,
      zipCode: this.data.zipCode,
      place: this.data.place,

      email: this.data.email,
      phone: this.data.phone,
      www: this.data.www,
    };
  }

  /**
   * Handlers
   */
  private onFormSubmit(e: Event) {
    e.preventDefault();
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.sendApplication();
    }
  }
}
