











































import { Component, Prop } from 'vue-property-decorator';

import Field from '@/shared/resources/components/forms/fields/Field.vue';
import { SelectFieldOptionInterface } from '@/shared/lib/interfaces/SelectFieldOptionInterface';

@Component
export default class SelectField extends Field {
  /**
   * Props
   */
  @Prop() private options!: SelectFieldOptionInterface[];

  /**
   * Getters
   */
  private get items(): SelectFieldOptionInterface[] {
    return this.options.map((option: SelectFieldOptionInterface) => {
      if (option.isHeader) {
        option.disabled = true;
      }

      return option;
    });
  }
}
