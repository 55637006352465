









import { Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class FormFieldset extends Vue {
  /**
   * Props
   */
  @Prop({ type: Boolean, default: false }) private dense!: boolean;

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'form-fieldset--dense': this.dense,
    };
  }
}
