import { AuthApplicationIndustry } from '@/app/modules/auth/interfaces/AuthApplicationInterface';

const applicationIndustryOptions: AuthApplicationIndustry[] = [
  {
    name: 'sklep typu party',
  },
  {
    name: 'sklep internetowy',
  },
  {
    name: 'firma dekoratorska',
  },
  {
    name: 'kwiaciarnia',
  },
  {
    name: 'restauracja/pub',
  },
  {
    name: 'klub nocny/dyskoteka',
  },
  {
    name: 'hotel',
  },
  {
    name: 'agencja eventowa',
  },
  {
    name: 'inna',
    commentRequired: true,
  },
];

export {
  applicationIndustryOptions,
};
